import axios from 'axios'

export default {
  async all({ userId }) {
    const response = await axios.get(`/custoMensal/${userId}/all`)
    return response
  },

  async create({ data }) {
    const response = await axios.post(`/custoMensal`, data)
    return response
  },

  async update({ id, data }) {
    const response = await axios.put(`/custoMensal/${id}`, data)
    return response
  },

  async delete(id) {
    const response = await axios.delete(`/custoMensal/${id}`)
    return response
  },
}
