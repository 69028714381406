<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card>
        <v-card-title style="justify-content: center; font-size: 24px">
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="form.numeroSinc"
              label="Número  máximo de dias sem sincronização"
            />
          </v-col>
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="form.numeroRasc"
              label="Número  máximo de rascunhos no APP"
            />
          </v-col>
        </v-card-title>
        <v-card-actions class="justify-end">
          <v-btn color="success" width="100" @click="updateConfig">
            SALVAR
          </v-btn>
          <v-btn color="error" width="100" @click="closed">
            <v-icon dark left> mdi-minus-circle </v-icon>
            FECHAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {},

  props: {
    codUsuario: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      form: {
        numeroSinc: null,
        numeroRasc: null,
      },

      loading: true,
      NumDiasSemSincronizar: null,
      NumMaxRascunho: null,
    }
  },
  mounted() {
    this.loadConfig(this.codUsuario)
  },
  methods: {
    loadConfig(CodUsuario) {
      this.api.get.configUser(CodUsuario).then(data => {
        this.form.numeroSinc = data.NumDiasSemSincronizar
        this.form.numeroRasc = data.NumMaxRascunho
        this.loading = false
      })
    },

    updateConfig() {
      if (this.codUsuario) {
        this.loading = true
        this.api.update
          .configUser(this.codUsuario, this.form)
          .then(response => {
            this.Swal.fire({
              title: 'Sucesso',
              text: response,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            })
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    },

    closed() {
      this.$root.$emit('showConfig')
    },
  },
}
</script>
