<template>
  <v-card class="ma-3">
    <v-dialog v-model="showView" style="hidden" scrollable max-width="350px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-col cols="10">
            <span style="font-size: 18px">
              {{ formTitle }}
            </span>
            <br />
            <span style="font-size: 15px"> {{ nomeUsuario }} </span>
          </v-col>
          <v-col cols="1">
            <v-btn icon dark @click="showView = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-toolbar>
        <v-card-text v-if="index == 1">
          <ul>
            <li v-for="item in fazendas" :key="item.text" class="pt-2">
              {{ item.text.toUpperCase() }}
            </li>
          </ul>
        </v-card-text>
        <v-card-text v-if="index == 0">
          <ul>
            <li v-for="item in agroindustrias" :key="item.text" class="pt-2">
              {{ item.text.toUpperCase() }}
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
    <base-loading v-if="loading" />
    <crud-list
      v-else
      v-model="consultores"
      :headers="headers"
      :slots="[
        'item.telefone',
        'item.FazendasQtd',
        'item.AgroindustriasQtd',
        'item.Status',
      ]"
      :actions="actions"
    >
      <template
        v-if="
          $user.get().role === 'admin' || $user.get().role === 'consultoria'
        "
        v-slot:btnCadastro
      >
        <router-link :to="`/${$user.get().role}/consultor/form`">
          <v-btn color="success"> Novo Consultor </v-btn>
        </router-link>
      </template>
      <template v-slot:[`item.Status`]="{ item }">
        <v-btn
          v-if="item.Status === 'Ativo' || item.Status === 1"
          rounded
          small
          plain
          text
          class="teal lighten-1 white--text"
          @click="updateStatus(item)"
          >{{ item.Status }}</v-btn
        >
        <v-btn
          v-else
          text
          rounded
          small
          plain
          class="red lighten-1 white--text"
          @click="updateStatus(item)"
          >{{ item.Status }}</v-btn
        >
      </template>
      <template v-slot:[`item.telefone`]="{ item }">
        {{ item.Celular ? celular(item.Celular) : ' - ' }}
      </template>
      <template v-slot:[`item.FazendasQtd`]="{ item }">
        {{ item.FazendasQtd ? item.FazendasQtd : '0' }}
        <v-icon
          v-if="item.FazendasQtd"
          title="Visualizar fazendas"
          color="primary"
          size="16"
          style="margin-left: 10px"
          @click="viewFarms(item)"
        >
          mdi-eye
        </v-icon>
      </template>
      <template v-slot:[`item.AgroindustriasQtd`]="{ item }">
        {{ item.AgroindustriasQtd ? item.AgroindustriasQtd : '0' }}
        <v-icon
          v-if="item.AgroindustriasQtd"
          title="Visualizar agroindústrias"
          color="primary"
          size="16"
          style="margin-left: 10px"
          @click="viewAgros(item)"
        >
          mdi-eye
        </v-icon>
      </template>
    </crud-list>
    <v-dialog
      v-model="showLog"
      style="hidden"
      width="500px"
      scrollable
      hide-overlay
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-col cols="12">
            <span style="font-size: 18px"> Configuração de consultor </span>
          </v-col>
        </v-toolbar>
        <v-card-text outlined>
          <div>
            <ConfigView v-if="showLog" :cod-usuario="codUsuario" />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showSync"
      style="hidden"
      width="500px"
      scrollable
      hide-overlay
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-col cols="12">
            <span style="font-size: 18px"> Configuração dos relatórios </span>
          </v-col>
        </v-toolbar>
      </v-card>
    </v-dialog>

    <CustoMensal
      v-if="custoMensal"
      v-model="custoMensal"
      :user="custoMensalItem"
    />
  </v-card>
</template>

<script>
import { celular } from '../../utils/masks'
import ConfigView from './ConfigView.vue'
import CustoMensal from './CustoMensal.vue'

export default {
  components: {
    ConfigView,
    CustoMensal,
  },
  data() {
    let btnAction = []

    const configConsultor = {
      title: 'Configurar consultor',
      color: 'primary',
      click: item => {
        this.codUsuario = String(item.id)
        this.showLog = true
      },
      icon: 'mdi-cog',
    }

    const editConsultor = {
      title: 'Editar consultor',
      color: 'yellow darken-3',
      click: item =>
        this.$router.push({
          path: 'consultor/form',
          query: {
            id: item.id,
          },
        }),
      icon: 'mdi-pencil',
    }

    const custoMensal = {
      title: 'Custo por visita mensal',
      color: 'green darken-3',
      click: item => {
        this.custoMensalKey++
        this.custoMensalItem = item
        this.custoMensal = true
      },
      icon: 'mdi-google-analytics',
    }

    if (
      this.$user.get().role === 'admin' ||
      this.$user.get().role === 'consultoria' ||
      this.$user.get().role === 'gestor'
    ) {
      btnAction = [custoMensal, configConsultor, editConsultor]
    } else {
      btnAction = [configConsultor]
    }

    return {
      actions: btnAction,
      form: {
        numeroSinc: null,
        numeroRasc: null,
      },
      showView: false,
      nomeUsuario: '',
      index: 1,
      fazendas: null,
      agroindustrias: null,
      showLog: false,
      showSync: false,
      dialog: false,
      custoMensal: false,
      custoMensalItem: null,
      custoMensalKey: 0,
      selectedConsultor: null,
      consultores: [],
      menuDataIni: false,
      dataInicio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      loadingData: {
        agroindustria: false,
        loadingAgroindustria: false,
      },
      requiredRules: [v => !!v || '* Obrigatório'],
      loading: true,
      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Status',
          sortable: true,
          width: 'auto',
          value: 'Status',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Nome',
          align: 'left',
          sortable: true,
          value: 'Nome',
          width: 'auto',
        },
        {
          text: 'Email',
          align: 'left',
          sortable: true,
          value: 'Email',
          width: 'auto',
        },
        {
          text: 'Telefone',
          align: 'left',
          sortable: true,
          value: 'telefone',
          width: 'auto',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Produtores',
          value: 'Produtores',
          width: 'auto',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Agroindústrias',
          value: 'AgroindustriasQtd',
          width: 'auto',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Fazendas',
          value: 'FazendasQtd',
          width: 'auto',
        },
      ],
    }
  },

  computed: {
    formTitle() {
      return this.index ? 'Fazendas consultor:' : 'Agroindústrias consultor:'
    },
  },

  mounted() {
    this.$root.$on('showConfig', () => {
      this.showLog = false
    })

    this.$root.$on('hiddenSync', () => {
      this.showSync = false
    })
  },

  created() {
    this.getConsultores()
  },
  methods: {
    viewFarms(item) {
      this.index = 1
      this.fazendas = item.Fazendas.map(item => {
        return {
          text: item.pessoajuridica.NomeFantasia
            ? item.pessoajuridica.NomeFantasia
            : item.pessoajuridica.RazaoSocial,
          value: item.CodFazenda,
          ...item,
        }
      })
      this.nomeUsuario = String(item.Nome)
      this.showView = true
    },

    viewAgros(item) {
      this.index = 0
      this.agroindustrias = item.Agroindustrias.map(item => {
        return {
          text: item.agroindustria.pessoajuridica.RazaoSocial
            ? item.agroindustria.pessoajuridica.RazaoSocial
            : item.agroindustria.pessoajuridica.NomeFantasia,
          value: item.CodAgroindustria,
          ...item,
        }
      })
      this.nomeUsuario = String(item.Nome)
      this.showView = true
    },

    celular,
    getConsultores() {
      this.api.get.consultores().then(data => {
        data = data.map(item => {
          item.Status = this.typeState(item.Status)
          return item
        })
        this.consultores = data
        this.loading = false
      })
    },

    typeState(status) {
      if (status === '1') {
        return 'Ativo'
      } else {
        return 'Inativo'
      }
    },

    updateStatus(item) {
      if (this.$user.get().role === 'admin') {
        this.Swal.fire({
          title: `${item.Status === 'Ativo' ? 'Inativar' : 'Ativar'} Consultor`,
          text: `Tem certeza que deseja ${
            item.Status === 'Ativo' ? 'inativar' : 'ativar'
          } esse consultor?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#ff5252',
          confirmButtonText: `Sim, desejo ${
            item.Status === 'Ativo' ? 'inativar' : 'ativar'
          }!`,
          cancelButtonText: 'Cancelar',
          preConfirm: () => {
            this.api.update
              .consultorState(item.id, {
                state: item.Status === 'Ativo' ? 0 : 1,
              })
              .then(() => {
                this.consultores = this.consultores.map(consultor => {
                  if (consultor.id === item.id) {
                    consultor.Status =
                      item.Status === 'Ativo' ? 'Inativo' : 'Ativo'
                  }
                  return consultor
                })

                this.Swal.fire({
                  title: 'Sucesso',
                  text: `Consultor ${
                    item.Status === 'Ativo' ? 'ativado' : 'inativado'
                  } com sucesso`,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 2000,
                })
              })
            return true
          },
        })
      } else {
        this.Swal.fire(
          'Não permitido!',
          'Somente o Admin pode alterar o Status dos consultores.',
          'error',
        )
      }
    },

    getAgroindustrias() {
      this.api.get.agroindustrias().then(data => {
        this.agroindustrias = data
        this.agroindustrias = this.agroindustrias.filter(item => {
          return item.pessoa?.pessoajuridica?.NomeFantasia
        })
        this.agroindustrias = this.agroindustrias.map(item => {
          return {
            name: item.pessoa.pessoajuridica.NomeFantasia,
            id: item.CodAgroindustria,
          }
        })
        this.loadingData.agroindustria = true
      })
    },

    getId(item) {
      if (item.length > 0) {
        this.selectedConsultor = item[0].id
      }
    },

    create() {
      if (!this.form.agroindustria) {
        this.Swal.fire(
          'Atenção',
          'Preencha os itens obrigatórios sinalizados com ("*").',
          'warning',
        )

        return
      }

      this.loadingData.loadingAgroindustria = true
      this.loadingData.agroindustria = false

      const data = {
        user: this.selectedConsultor,
        agroindustria: this.form.agroindustria,
        dataInicio: this.dataInicio,
      }

      const find = this.agroindustrias.find(
        item => item.id === data.agroindustria,
      )
      let status = false

      this.consultores = this.consultores.map(item => {
        if (data.user === item.id) {
          const findAgro = item.agroindustrias.find(
            agroindustria =>
              agroindustria.agroindustria.CodAgroindustria ===
              data.agroindustria,
          )

          if (findAgro) {
            this.Swal.fire(
              'Atenção',
              'Esta agroindustria já está cadastrado para este consultor!',
              'warning',
            )

            this.form.agroindustria = ''
            this.dataInicio = ''
            this.loadingData.loadingAgroindustria = false
            this.loadingData.agroindustria = true
            this.dataInicio = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000,
            )
              .toISOString()
              .substr(0, 10)

            status = false
          } else {
            status = true
          }
        }

        return item
      })

      if (!status) return

      this.api.save
        .agronegocioAgroindustria(data)
        .then(result => {
          if (result) {
            this.Swal.fire({
              title: 'Sucesso',
              text: 'Consultor vinculado com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              this.form.agroindustria = ''
              this.dataInicio = ''
              this.dialog = false
              this.loadingData.loadingAgroindustria = false
              this.loadingData.agroindustria = true
              this.dataInicio = new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000,
              )
                .toISOString()
                .substr(0, 10)
            })

            this.consultores = this.consultores.map(item => {
              if (data.user === item.id) {
                item.agroindustrias.push({
                  agroindustria: {
                    CodAgroindustria: find.id,
                    pessoajuridica: {
                      NomeFantasia: find.name,
                    },
                  },
                  DataInicio: data.dataInicio,
                })
              }

              return item
            })
          }
        })
        .catch(() => {
          this.Swal.fire(
            'Atenção',
            'Não foi possível vincular este consultor à agroindústria! Tente novamente.',
            'warning',
          )

          this.form.agroindustria = ''
          this.dataInicio = ''
          this.dialog = false
          this.loadingData.loadingAgroindustria = false
          this.loadingData.agroindustria = true
          this.dataInicio = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000,
          )
            .toISOString()
            .substr(0, 10)
        })
    },
  },
}
</script>
