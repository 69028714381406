<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog v-model="dialog" max-width="800">
        <v-card>
          <v-toolbar color="primary" dark>Custo por visita mensal</v-toolbar>
          <v-card-text>
            <v-card class="px-4 py-4">
              <v-form ref="form">
                <v-card-title v-if="updated" class="text--primary">
                  Editar Custo por visita mensal
                </v-card-title>
                <v-card-title v-else class="text--primary">
                  Novo Custo por visita mensall
                </v-card-title>
                <v-row>
                  <v-col cols="4" sm="4" md="4">
                    <vuetify-money
                      v-model="form.meta"
                      name="custoMensal"
                      label="Custo por visita mensal *"
                      :options="{ ...options, precision: 2 }"
                      :required="true"
                      :rules="requiredRules"
                    />
                  </v-col>

                  <v-col cols="4" sm="4" md="4">
                    <v-menu
                      v-model="dataDeReferenciaMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="
                            form.dataDeReferencia
                              ? moment(form.dataDeReferencia).format(
                                  'DD/MM/YYYY',
                                )
                              : ''
                          "
                          label="Data de referência *"
                          prepend-icon="mdi-calendar"
                          color="teal"
                          readonly
                          :rules="requiredRules"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="form.dataDeReferencia"
                        style="margin: 0px"
                        color="teal"
                        required-mapa
                        locale="pt-br"
                        @input="dataDeReferenciaMenu = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form>

              <v-card-actions class="justify-end">
                <v-btn
                  color="green darken-1"
                  text
                  :loading="loading.create"
                  :disabled="loading.create"
                  @click="createOrUpdate"
                  >Salvar</v-btn
                >
                <v-btn text @click="dialog = false">Fechar</v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>

          <v-card class="px-4 py-8">
            <base-loading v-if="loading.list" />
            <crud-list
              v-else
              v-model="custosMensais"
              :headers="headers"
              :items-per-page="5"
              :actions="actions"
              :slots="['item.meta', 'item.dataDeReferencia']"
              class="elevation-1"
            >
              <template v-slot:[`item.meta`]="{ item }">
                {{ item.meta ? currencyFormatter(item.meta, 'R$') : '' }}
              </template>

              <template v-slot:[`item.dataDeReferencia`]="{ item }">
                {{
                  item.dataDeReferencia
                    ? moment(item.dataDeReferencia).format('DD/MM/YYYY')
                    : ''
                }}
              </template>
            </crud-list>
          </v-card>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import CustoMensalService from '../../services/api/custoMensal'
import { currencyFormatter } from '../../utils/formatter'
export default {
  name: 'CustoMensal',
  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    user: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      currencyFormatter,

      loading: {
        list: false,
        create: false,
      },

      dataDeReferenciaMenu: false,
      updated: false,

      form: {
        meta: '',
        dataDeReferencia: '',
      },

      options: {
        locale: 'pt-BR',
        prefix: 'R$',
        suffix: '',
        length: 14,
        precision: 2,
      },

      requiredRules: [v => !!v || '* Obrigatório'],

      actions: [
        {
          icon: 'mdi-delete',
          tooltip: 'Excluir',
          click: item => {
            this.delete(item)
          },
          color: 'red darken-3',
        },
        {
          icon: 'mdi-pencil',
          tooltip: 'Editar',
          click: item => {
            this.edit(item)
          },
          color: 'yellow darken-3',
        },
      ],

      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },
        { text: 'Custo por visita mensal', value: 'meta' },
        { text: 'Data de referência', value: 'dataDeReferencia' },
      ],

      custosMensais: [],
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  created() {
    this.index()
  },

  methods: {
    async index() {
      if (!this?.user?.user_id) return

      try {
        this.loading.list = true
        const response = await CustoMensalService.all({
          userId: this.user.user_id,
        })
        this.custosMensais = response.data
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao buscar custos mensais',
        })
      } finally {
        this.loading.list = false
      }
    },

    createOrUpdate() {
      if (this.updated) {
        this.update()
      } else {
        this.create()
      }
    },

    async create() {
      try {
        const valid = await this.$refs.form.validate()

        if (!valid) {
          this.Toast().fire({
            icon: 'error',
            title: 'Preencha os campos obrigatórios',
          })
          return
        }

        const existDataDeReferencia = this.custosMensais.find(
          custoMensal =>
            moment(custoMensal.dataDeReferencia).format('MM/YYYY') ===
            moment(this.form.dataDeReferencia).format('MM/YYYY'),
        )

        if (existDataDeReferencia) {
          this.Toast().fire({
            icon: 'error',
            title: 'Já existe um custo por visita mensal para esse mês',
          })
          return
        }

        this.loading.create = true
        const response = await CustoMensalService.create({
          data: {
            meta: this.form.meta,
            dataDeReferencia: this.form.dataDeReferencia,
            userId: this.user.user_id,
          },
        })

        this.custosMensais.push(response.data)

        this.form = {
          meta: '',
          dataDeReferencia: '',
        }

        this.Toast().fire({
          icon: 'success',
          title: 'Custo por visita mensal criado com sucesso',
        })
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao criar custo por visita mensal',
        })
      } finally {
        this.loading.create = false
      }
    },

    async update() {
      try {
        const valid = await this.$refs.form.validate()

        if (!valid) {
          this.Toast().fire({
            icon: 'error',
            title: 'Preencha os campos obrigatórios',
          })
          return
        }

        this.loading.create = true

        const response = await CustoMensalService.update({
          id: this.form.id,
          data: {
            meta: this.form.meta,
            dataDeReferencia: this.form.dataDeReferencia,
            userId: this.user.user_id,
          },
        })

        this.custosMensais = this.custosMensais.filter(
          custoMensal => custoMensal.id !== this.form.id,
        )

        this.custosMensais.push(response.data)

        this.form = {
          id: '',
          meta: '',
          dataDeReferencia: '',
          userId: '',
        }

        this.Toast().fire({
          icon: 'success',
          title: 'Custo por visita mensal atualizado com sucesso',
        })
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao atualizar custo por visita mensal',
        })
      } finally {
        this.loading.create = false
        this.updated = false
      }
    },

    edit(item) {
      this.form = {
        id: item.id,
        meta: item.meta,
        dataDeReferencia: item.dataDeReferencia,
        userId: item.userId,
      }

      this.updated = true
    },

    async delete(item) {
      try {
        const response = await CustoMensalService.delete(item.id)

        if (response.status === 204) {
          this.custosMensais = this.custosMensais.filter(
            custoMensal => custoMensal.id !== item.id,
          )
        }

        this.Toast().fire({
          icon: 'success',
          title: 'Custo por visita mensal excluído com sucesso',
        })
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao excluir custo por visita mensal',
        })
      }
    },
  },
}
</script>
